<template>
    <!-- 音频抽屉 -->
    <div class="audioDrawer">

        <div class="actuate_box">
            <div class="tab-header">
                <div class="tab-background" :style="backgroundStyle"></div>
                <div class="tab-slider" :style="sliderStyle"></div>
                <button v-for="(tab, index) in drivens" :key="tab.id" @click="selectTabDrawer(tab.id, index)"
                    :class="{ active: drivenIn === tab.id }">
                    {{ tab.title }}
                </button>
            </div>
            <!-- 文字驱动 -->
            <div class="textDriven" v-if="activeIndex == 0">
                <div class="textComposition">
                    <div class="goCraft">
                        <div class="left">音频选择</div>
                        <div class="right" @click="desynthesis">去合成音频</div>
                    </div>
                </div>
                <!-- 搜索 -->
                <div class="search">
                    <el-input placeholder="请输入内容" @change="search" prefix-icon="el-icon-search" v-model="searchValue">
                    </el-input>
                </div>
                <!-- 表格 -->
                <div class="">
                    <tabulation :items="SyntheticAudioList" @selection-change="handleSelectionChange"
                        @delete-item="handleDeleteItem" @download-item="handleDownloadItem"
                        @refresh-item="handleRefreshItem" />
                </div>
                <!-- 分页 -->
                <div class="block">
                    <el-pagination @current-change="handlePageChange" :page-sizes="[10]" :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper" :total="SyntheticAudioTotal">
                    </el-pagination>
                </div>
                <!-- 确认 -->

            </div>

            <div class="audioDriver" v-if="activeIndex == 1">

                <div class="search_append">
                    <div class="search"></div>
                    <div class="append">
                        <div @click="dialogShow = true">上传到资料库</div>
                    </div>
                </div>

                <div class="list">
                    <div @click="selectAudeo(item, index)" :class="selectAudeoIndex == index ? 'activeItem' : 'item'"
                        v-for="(item, index) in dataBankList" :key="index">
                        <div class="avatar"><img src="../../assets/xingbieweizhi.png" alt=""></div>
                        <div class="name_duration">
                            <div class="name">{{ item.name }}</div>
                            <div class="duration">音频时长:{{ item.duration }}</div>
                        </div>

                        <div class="playBack_pause" v-if="item.url">
                            <div class="playBack" @click.stop="playMyAudio(item.url, index)" v-if="item.show == false">
                                <img src="../../assets/bofang.png" alt="">
                            </div>
                            <div class="playBack" @click.stop="endMyAudio(index)" v-if="item.show == true"><img
                                    src="../../assets/zanting.png" alt=""></div>
                        </div>
                        <div class="delete" @click="deleted(item, index)"> <i class="el-icon-close"></i></div>
                    </div>
                </div>


                <div class="pading">
                    <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                        :current-page="dataBankPage" :page-size="18" :total="dataBankTotal" :page-sizes="[18]"
                        @current-change="handleSizeChange" />
                </div>






                <!-- <div class="upload">
                    <div v-if="!audioPathOss">
                        <audioUpload @audioPath="audioPath" @audioDuration="audioDuration" :maxDuration="9999" />
                    </div>
                    <div class="audioPlayer" v-if="audioPathOss">
                        <audioPlayer :aPath="audioPathOss" />
                        <div class="reupload" @click="reupload">点击重新上传文件</div>
                    </div>
                </div>
                录制建议
                <div class="suggestion">
                    <div>
                        <div class="title">录制建议</div>
                        <div class="item" v-for="(item, index) in suggestionList" :key="index">
                            <div class="itemTile">{{ item.title }}</div>
                            <div class="itemContent" v-for="(ite, ind) in item.text" :key="ind">
                                {{ ite }}
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="verify_cancel">
                <div class="verify" @click="verify">
                    确认
                </div>
            </div>
        </div>


        <el-dialog title="上传音频到资料库" append-to-body :visible.sync="dialogShow" width="30%">

            <div class="uploadAudeo">
                <div v-if="!audioPathOss">
                    <audioUpload @audioPath="audioPath" @audioDuration="audioDuration" :maxDuration="9999" />
                </div>
                <div class="audioPlayer" v-if="audioPathOss">
                    <audioPlayer :aPath="audioPathOss" />
                    <div class="reupload" @click="reupload">点击重新上传文件</div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="uploadAudeo">上 传</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import tabulation from '../../../components/tabulation.vue';
import audioUpload from '../../../components/audioUpload.vue'
import audioPlayer from '../../../components/AudioPlayer.vue'
import { getSyntheticAudioList, deleteSyntheticAudio, getImagedataBank, dataBankDetele, uploadDataBank } from '../../../api/api'

export default {
    props: {
        audioDrawer: {
            type: Boolean,
            default: null
        }
    },
    components: {
        tabulation,
        audioUpload,
        audioPlayer,

    },
    computed: {
        sliderStyle() {
            return {
                transform: `translateX(${this.activeIndex * 100}%)`
            }
        },
        backgroundStyle() {
            return {
                transform: `translateX(${this.activeIndex * 100}%)`
            }
        },

        sliderStyleImage() {
            return {
                transform: `translateX(${this.activeImageIndex * 100}%)`
            }
        },
        backgroundStyleImage() {
            return {
                transform: `translateX(${this.activeImageIndex * 100}%)`
            }
        },
    },
    data() {
        return {
            audio: new Audio(),

            activeIndex: 0,//控制文本-语音切换
            drivenIn: 'textDriven',
            drivens: [
                { id: 'textDriven', title: '文本驱动' },
                { id: 'audioDriver', title: '音频驱动' }
            ],
            searchValue: '',//文本驱动搜索
            suggestionList: [
                {
                    title: '录制文本',
                    text: [
                        '1.克隆声音会保留上传音频的语气风格，为避免语气违和，需 准备贴近最终使用场景的文本，如用于电商直播的声音使用。 电商带货文本进行录制。'
                    ]
                },
                {
                    title: '录制环境',
                    text: [
                        '1.保障无环境背景噪声、无明显回声和混响、信噪比大于30dB。',
                        '2.录制期间话筒距离嘴部10cm-50cm之间，保持位置距离稳定。',
                        '3.录制过程中保持姿态稳定，减少人为噪声，如肢体碰撞声、 服装摩擦声、桌椅移动声、鼠标键盘声等。'
                    ]
                }
            ],
            SyntheticAudioList: [],//合成音频列表
            SyntheticAudioPage: 1,//合成音频列表分页
            SyntheticAudioTotal: 0,//合成音频列表总数

            selectedItem: {},//文本合成选中的音频
            audioPathOss: '',//上传到OSS返回的音频地址

            duration: '',//上传到资料库，上传音频的时长

            dataItem: {},





            dataBankList: [],
            dataBankPage: 1,
            dataBankTotal: 0,

            selectAudeoIndex: -1,

            dialogShow: false

        }
    },
    methods: {

        // 上传音频到资料库
        uploadAudeo() {

            if (!this.audioPathOss) return this.$message.error('请选择音频');
            let data = {
                name: '音频文件',
                url: this.audioPathOss,
                file_type: 2,
                duration: Math.floor(this.duration)
            }
            uploadDataBank(data).then(res => {
                if (!res) return
                if (res.data.code == 10000) {
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                    this.dialogShow = false
                    this.getImagedataBank()
                }
            })
        },


        playMyAudio(url, index) {
            // 判断是否是同一个音频，不是的话其他为false
            for (let i = 0; i < this.dataBankList.length; i++) {
                if (i !== index) {
                    this.dataBankList[i].show = false
                }
            }
            this.dataBankList[index].show = true//修改显示
            // console.log(this.list[index])
            this.$forceUpdate();

            this.playIndex = index
            this.audio.src = url
            this.audio.play();//播放
            this.audio.onended = () => {
                // console.log('要结束了')
                this.dataBankList[index].show = false
            }
        },

        endMyAudio(index) {
            this.audio.pause();//暂停
            this.$forceUpdate();
            this.dataBankList[index].show = false
        },

        handleSizeChange(e) {
            this.dataBankPage = e
            this.getImagedataBank()
        },
        selectAudeo(ite, index) {
            this.selectAudeoIndex = index
            console.log(this.selectAudeoIndex)
        },


        deleted(item, index) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: item.id
                }
                dataBankDetele(data).then(res => {
                    if (!res) return
                    if (res.data.code == 10000) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.dataBankList.splice(index, 1)
                        this.getImagedataBank()
                    }
                })
            }).catch(() => {
            });
        },


        // 控制文本合成音频框
        desynthesis() {
            this.$emit('audioShow', true)
        },
        selectTabDrawer(tabId, index) {
            this.activeIndex = index;
        },

        handleSelectionChange(selectedItem) {
            // console.log('选中的文本合成音频', selectedItem);
            this.selectedItem = selectedItem
        },
        handleDeleteItem(item) {
            // 实现删除逻辑
            console.log('Deleting item:', item);
            const index = this.SyntheticAudioList.findIndex(human => human.name === item.name);
            if (index !== -1) {

                let data = { id: item.id }
                deleteSyntheticAudio(data).then(res => {
                    if (res.data.code == 10000) {
                        this.SyntheticAudioList.splice(index, 1);
                        this.SyntheticAudioTotal--
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                })
            }
        },
        handleDownloadItem(item) {
            // 实现下载逻辑
            console.log('下载:', item);
            // 这里可以添加实际的下载逻辑
        },
        // 刷新
        handleRefreshItem(item) {
            // console.log(item, 'asdasdasdfafsd')
            let list = this.SyntheticAudioList
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == item.id) {
                    // console.log('赋值')
                    this.SyntheticAudioList[i] = item
                }
            }
        },
        // 合成音频列表分页
        handlePageChange(e) {
            this.SyntheticAudioPage = e
            this.getSyntheticAudioList()
        },
        verify() {
            if (this.activeIndex == 0) {

                this.dataItem.type = 1
                this.dataItem.synthetic_audio_id = this.selectedItem.id
                this.dataItem.audio_url = this.selectedItem.audio_url
            } else if (this.activeIndex == 1) {

                if (this.selectAudeoIndex == -1) return this.$message.error('请选择一条音频');
                this.dataItem.type = 2
                this.dataItem.duration = Math.trunc(this.dataBankList[this.selectAudeoIndex].duration)
                this.dataItem.audio_url = this.dataBankList[this.selectAudeoIndex].url

            }
            this.$emit('audioDra', false)
            this.$emit('audioData', this.dataItem)
        },
        // 上传音频
        audioPath(e) {
            console.log(e)
            this.audioPathOss = e
        },
        audioDuration(e) {
            this.duration = e
        },
        // 重新上传
        reupload() {
            this.audioPathOss = ''
        },
        // 搜索
        search() {
            this.SyntheticAudioPage = 1
            this.getSyntheticAudioList()
        },


        // 获取资料库音频
        getImagedataBank() {
            let params = {
                file_type: 2,
                keyword: '',
                page: this.dataBankPage,
                per_page: 18

            }
            getImagedataBank(params).then(res => {
                if (!res) return
                if (res.data.code == 10000) {
                    this.dataBankList = []
                    this.dataBankTotal = res.data.total
                    let list = res.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].show = false
                        list[i].gender = 0
                        this.dataBankList.push(list[i])
                    }

                }
            })
        },

        // 获取音频合成列表
        getSyntheticAudioList() {
            let params = {
                page: this.SyntheticAudioPage,
                page_size: 10,
                name: this.searchValue
            }
            getSyntheticAudioList(params).then(res => {
                if (res.data.code == 10000) {
                    this.SyntheticAudioList = res.data.data
                    this.SyntheticAudioTotal = res.data.total
                }
            })
        }
    },
    created() {
        this.getImagedataBank()
        this.getSyntheticAudioList()
    }
}
</script>
<style lang="less" scoped>
.audioDrawer {
    /deep/.rtl {
        width: 1008px !important;
        padding: 0 20px;
    }

    .actuate_box {
        .tab-header {
            display: flex;
            background-color: #D8D8D8;
            position: relative;
            overflow: hidden;
            border-radius: 50px;
            width: 966px;
            height: 70px;

            .tab-background {
                margin: 5px 10px;
                position: absolute;
                top: 0;
                left: 0;
                height: 60px;
                width: 473px;
                background-color: #fff;
                transition: transform 0.3s ease;
                border-radius: 50px;

                .tab-slider {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 50%;
                    background-color: #007bff;
                    transition: transform 0.3s ease;
                }
            }

            button {
                flex: 1;
                background: none;
                border: none;
                padding: 15px 30px;
                font-size: 16px;
                cursor: pointer;
                transition: color 0.3s ease;
                z-index: 1;
                position: relative;
            }

            .tab-header button.active {
                font-weight: bold;
                color: #007bff;
                border-radius: 50px;
            }
        }

        .textDriven {
            .textComposition {
                margin: 30px 0;

                .goCraft {
                    display: flex;
                    justify-content: space-between;

                    .left {
                        color: #3D3D3D;
                        font-size: 20px;
                    }

                    .right {
                        padding: 5px 10px;

                        cursor: pointer;
                        background-color: #3E7AFE;
                        border-radius: 5px;
                        color: #fff;
                    }
                }
            }

            .search {
                width: 250px;
                margin: 20px 0;
            }

            .block {
                margin-top: 20px;
                display: flex;
                justify-content: center;
            }


        }

        .audioDriver {
            margin: 30px 0;

            .search_append {
                display: flex;
                justify-content: space-between;
                margin: 25px;

                .append {
                    padding: 5px 10px;
                    cursor: pointer;
                    background-color: #3E7AFE;
                    border-radius: 5px;
                    color: #fff;
                    cursor: pointer;

                }
            }

            .list {
                display: flex;
                flex-wrap: wrap;
            }

            .activeItem {
                border: 1px solid #3E7AFE;
                display: flex;
                justify-content: space-between;
                width: 280px;
                margin: 10px;
                padding: 5px;
                border-radius: 10px;

                .avatar {
                    img {
                        width: 50px;
                        height: 50px;
                        display: block;
                    }
                }

                .name_duration {
                    margin-left: 10px;
                    width: 120px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .name {
                    font-size: 14px;

                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .duration{
                        font-size: 10px;
                        color: #969494;
                    }
                }

                .playBack_pause {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 40px;
                        height: 40px;
                        display: block;
                    }
                }
            }

            .item {
                display: flex;
                justify-content: space-between;
                border: 1px solid #969494;
                width: 280px;
                margin: 10px;
                padding: 5px;
                cursor: pointer;
                border-radius: 10px;
                .avatar {
                    img {
                        width: 50px;
                        height: 50px;
                        display: block;
                    }
                }

                .name_duration {
                    margin-left: 10px;
                    width: 120px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .name {
                    font-size: 14px;

                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .duration{
                        font-size: 10px;
                        color: #969494;
                    }
                }


                .playBack_pause {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 40px;
                        height: 40px;
                        display: block;
                    }
                }

            }

            .pading {
                padding: 20px;
                display: flex;
                justify-content: center;
            }

            // .upload {

            // }

        }

        .verify_cancel {
            position: sticky;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: right;
            background: #fff;
            padding: 10px 20px;

            div {
                cursor: pointer;
                /*悬停变小手的属性*/
                width: 167px;
                height: 55px;
                border-radius: 10px;
                line-height: 55px;
                text-align: center;
                font-size: 24px;
                margin-right: 15px;
                background: #3E7AFE;
                color: #fff;
            }
        }
    }
}

.uploadAudeo {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .audioPlayer {
        margin-top: 30px;

        .reupload {
            text-align: center;
            cursor: pointer;
            /*悬停变小手的属性*/
            color: #3E7AFE;
            font-size: 20px;
            margin-top: 20px
        }
    }
}

.suggestion {
    display: flex;
    height: 300px;

    .title {
        font-size: 24px;
        color: #3D3D3D;
    }

    .item {
        .itemTile {
            line-height: 50px;

        }
    }

    .jianyiImg {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            margin-top: 50px;
        }

        .confirm_cancellation {
            display: flex;
            justify-content: right;

            .confirm {
                margin-right: 10px;
            }
        }
    }
}
</style>